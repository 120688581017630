/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
/* eslint-disable react/no-danger */
import React from 'react';
import _ from 'lodash';
import Img from 'gatsby-image';
import loadable from '@loadable/component';
import PropTypes from 'prop-types';
import { Layout, PostMeta, AboutWidget } from '../../components';
import postStyles from './post.module.scss';

const MoreResources = loadable(() => import('../../components/moreResources/moreResources'));
const Sidebar = loadable(() => import('../../components/sidebar/sidebar'));
const NearbyLocations = loadable(() => import('../../components/nearbyLocations/nearbyLocations'));
const AuthorProfile = loadable(() => import('../../components/authorProfile/authorProfile'));

const Post = ({ pageContext }) => {
    const {
        main,
        featuredImg,
        content,
        contentContainer,
        upperContent,
        sideBarDesktop,
        sideBarMobile,
    } = postStyles;
    const { wordpressPost, relatedPosts, postCity } = pageContext;
    const fluid = _.get(wordpressPost, 'featured_media.localFile.childImageSharp.fluid');
    const { yoast_wpseo_title, yoast_wpseo_metadesc } = wordpressPost.yoast_meta;

    const showNearbyLocations = _.size(postCity) && (_.size(postCity.node.suburbs) || _.size(postCity.node.parentCity));

    const renderNearbyLocationsOrSidebar = () => {
        return showNearbyLocations ? (
            <NearbyLocations city={postCity.node} state={postCity.node.state} isPost />
        ) : (
            <Sidebar />
        );
    };

    return (
        <Layout metaTitle={yoast_wpseo_title} metaDescription={yoast_wpseo_metadesc}>
            <div className={main}>
                <h1 dangerouslySetInnerHTML={{ __html: wordpressPost.title }} />
                <PostMeta node={wordpressPost} />
                {wordpressPost.featured_media && wordpressPost.featured_media.localFile ? (
                    <Img fadeIn={false} fluid={fluid} className={featuredImg} />
                ) : null}
                <div className={contentContainer}>
                    <div className={upperContent}>
                        <div
                            dangerouslySetInnerHTML={{ __html: wordpressPost.content }}
                            className={content}
                        />
                        <AuthorProfile author={wordpressPost.author} />
                    </div>
                    <div className={sideBarDesktop}>
                        <AboutWidget />
                        {renderNearbyLocationsOrSidebar()}
                    </div>
                </div>
            </div>
            <div className={sideBarMobile}>
                <AboutWidget />
                {renderNearbyLocationsOrSidebar()}
            </div>
            {_.isEmpty(relatedPosts) ? null : (
                <MoreResources posts={relatedPosts} data-testid="posts" />
            )}
        </Layout>
    );
};

Post.propTypes = {
    pageContext: PropTypes.shape({
        postCity: PropTypes.shape({
            node: PropTypes.shape({
                name: PropTypes.string,
                slug: PropTypes.string,
                state: PropTypes.shape({
                    name: PropTypes.string,
                    slug: PropTypes.string,
                }),
                suburbs: PropTypes.arrayOf(
                    PropTypes.shape({
                        name: PropTypes.string,
                        slug: PropTypes.string,
                    })
                ),
                parentCity: PropTypes.shape({
                    name: PropTypes.string,
                    slug: PropTypes.string,
                    suburbs: PropTypes.arrayOf(
                        PropTypes.shape({
                            name: PropTypes.string,
                            slug: PropTypes.string,
                        })
                    ),
                }),
            }),
        }),
        wordpressPost: PropTypes.shape({
            yoast_meta: PropTypes.shape({
                yoast_wpseo_title: PropTypes.string,
                yoast_wpseo_metadesc: PropTypes.string,
            }),
            author: PropTypes.shape({
                name: PropTypes.string,
                description: PropTypes.string,
                avatar_urls: PropTypes.shape({
                    wordpress_96: PropTypes.string,
                }),
            }),
            categories: PropTypes.arrayOf(
                PropTypes.shape({
                    name: PropTypes.string,
                    path: PropTypes.string,
                })
            ),
            date: PropTypes.string,
            title: PropTypes.string,
            content: PropTypes.string,
            featured_media: PropTypes.shape({
                localFile: PropTypes.shape({
                    childImageSharp: PropTypes.shape({
                        fluid: PropTypes.object,
                    }),
                }),
            }),
        }),
        relatedPosts: PropTypes.arrayOf(PropTypes.shape({})),
    }).isRequired,
};

export default Post;
